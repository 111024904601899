const Index = () => {
  return (
    <img
      src="https://generate.imgix.net/logo-black.png"
      alt="Generate"
      style={{
        width: "40%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        opacity: 0.05,
      }}
    />
  );
};
export default Index;
